import { render, staticRenderFns } from "./Calculation.vue?vue&type=template&id=64b0b304&scoped=true&"
import script from "./Calculation.vue?vue&type=script&lang=js&"
export * from "./Calculation.vue?vue&type=script&lang=js&"
import style0 from "./Calculation.vue?vue&type=style&index=0&id=64b0b304&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64b0b304",
  null
  
)

/* custom blocks */
import block0 from "./Calculation.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseSelect: require('/app/components/base/Select.vue').default,BaseCheckbox: require('/app/components/base/Checkbox.vue').default,BaseInput: require('/app/components/base/Input.vue').default,BaseBtn: require('/app/components/base/Btn.vue').default})
