//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'UiCalculatorTable',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    table() {
      const res = [];
      const { carBrand, carNumber, carModel, dateProduction, carVIN, carEngineVolume, carColor } = this.data;

      if (carBrand?.name) {
        res.push({ title: this.$t('carBrand'), value: carBrand.name || '&#8212;' });
      }
      if (carNumber) {
        res.push({ title: this.$t('carNumber'), value: carNumber || '&#8212;' });
      }
      if (carModel?.name) {
        res.push({ title: this.$t('carModel'), value: carModel.name || '&#8212;' });
      }
      if (dateProduction) {
        res.push({ title: this.$t('dateProduction'), value: dateProduction || '&#8212;' });
      }

      return res;
    },
  },
}
