//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'UiLinkSupport',
  props: {
    blok: {
      type: Object,
      required: true,
    },
    imgSize: {
      type: Number,
      default: 135, // 1.5x
    }
  },
}
