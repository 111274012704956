var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-input",class:{
    'base-input--focused': _vm.focused,
    'base-input--active': _vm.hasValue || _vm.focused || _vm.persistentFocus,
    'base-input--error': _vm.error,
    'base-input--disabled': _vm.disabled,
    'base-input--pending': _vm.pending,
    'base-input--icon': _vm.withIcon,
  }},[_c('div',{staticClass:"base-input__wr"},[_c('div',{staticClass:"base-input__label"},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),(_vm.withIcon)?_c('IconEmail',{staticClass:"base-input__icon"}):_vm._e(),_vm._v(" "),(_vm.maskType === 'mask')?_c('the-mask',{staticClass:"base-input__input",attrs:{"id":_vm.id,"name":_vm.name,"type":_vm.type,"value":_vm.value,"disabled":_vm.disabled || _vm.pending,"placeholder":_vm.placeholder ? _vm.placeholder : null,"autocomplete":_vm.autocomplete,"mask":_vm.mask,"masked":true},on:{"input":_vm.handleInput},nativeOn:{"focus":function($event){return _vm.handleFocus.apply(null, arguments)},"blur":function($event){return _vm.handleBlur.apply(null, arguments)},"change":function($event){return _vm.handleChange.apply(null, arguments)}}}):(_vm.maskType === 'number')?_c('VueNumber',_vm._b({staticClass:"base-input__input",attrs:{"id":_vm.id,"name":_vm.name,"type":_vm.type,"value":_vm.value,"disabled":_vm.disabled || _vm.pending,"placeholder":_vm.placeholder ? _vm.placeholder : null,"autocomplete":_vm.autocomplete},on:{"input":_vm.handleInput,"change":_vm.handleInput,"focus":_vm.handleFocus,"blur":_vm.handleBlur}},'VueNumber',_vm.bindNumberOptions,false)):_c('input',{staticClass:"base-input__input",attrs:{"id":_vm.id,"name":_vm.name,"type":_vm.type,"disabled":_vm.disabled || _vm.pending,"placeholder":_vm.placeholder ? _vm.placeholder : null,"autocomplete":_vm.autocomplete},domProps:{"value":_vm.value},on:{"input":_vm.handleInput,"focus":_vm.handleFocus,"blur":_vm.handleBlur}})],1),_vm._v(" "),(_vm.error)?_c('div',{staticClass:"base-input__error -t4",domProps:{"innerHTML":_vm._s(_vm.error)}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }