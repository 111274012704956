import { render, staticRenderFns } from "./Calculator.vue?vue&type=template&id=7da0fef1&scoped=true&"
import script from "./Calculator.vue?vue&type=script&lang=js&"
export * from "./Calculator.vue?vue&type=script&lang=js&"
import style0 from "./Calculator.vue?vue&type=style&index=0&id=7da0fef1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7da0fef1",
  null
  
)

/* custom blocks */
import block0 from "./Calculator.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseBtn: require('/app/components/base/Btn.vue').default,BaseInputPlate: require('/app/components/base/InputPlate.vue').default,BaseInput: require('/app/components/base/Input.vue').default,BaseSelect: require('/app/components/base/Select.vue').default,UiCalculatorTable: require('/app/components/ui/calculator/Table.vue').default,UiCalculatorProducts: require('/app/components/ui/calculator/Products.vue').default,UiLinkSupport: require('/app/components/storyblok/ui/LinkSupport.vue').default,UiCalculatorCalculation: require('/app/components/ui/calculator/Calculation.vue').default})
