//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex'
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { getError } from "~/utils/vuelidate";

export default {
  name: 'BlokHomeCalculator',
  mixins: [validationMixin],
  props: {
    blok: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isVisibleAos: false,
      activeTab: 0,
      initLoad: false,
      pending: false,
      byNumber: {
        number: '', // ВМ4004СА
        data: null,
        errorCode: null,
      },
      withoutNumber: {
        price: '',
        year: null,
        data: null,
        errorCode: null,
      },
      maxYearCarProduction: 2014,
      minPrice: 200000,
      maxPrice: 4000000,
      limitKilometers: 15000,
      errorResp: null,
    }
  },
  validations: {
    byNumber: {
      number: {
        required,
        isValid(value) {
          return value?.replace(/\s/g, '')?.length === 8;
        },
      },
    },
    withoutNumber: {
      price: {
        required,
        min(value) {
          return value ? parseFloat(value) > this.minPrice : true;
        },
        max(value) {
          return value ? parseFloat(value) < this.maxPrice : true;
        },
      },
      year: {
        required,
      },
    },
  },
  computed: {
    ...mapState('hero', [
      'heroNumber',
      'heroTimespan',
    ]),
    aosAnimate() {
      return this.isVisibleAos ? 'aos-animate' : null;
    },
    errors() {
      return {
        byNumber: {
          number: getError(this.$v.byNumber.number, {
            required: this.$t('field-required'),
            isValid: this.$t('invalid-value'),
          }),
        },
        withoutNumber: {
          price: getError(this.$v.withoutNumber.price, {
            required: this.$t('field-required'),
            min: `${this.$t('price-min')} ${this.filterNumber(this.minPrice, '&nbsp;')}`,
            max: `${this.$t('price-max')} ${this.filterNumber(this.maxPrice, '&nbsp;')}`,
          }),
          year: getError(this.$v.withoutNumber.year, {
            required: this.$t('field-required'),
          }),
        },
      };
    },
    heading() {
      switch(this.index) {
        case 0:
          return 'h1';
        default:
          return 'h2';
      }
    },
    blokByNumber() {
      return this.blok.tab_by_number?.[0] || {};
    },
    blokWithoutNumber() {
      return this.blok.tab_no_number?.[0] || {};
    },
    tabs() {
      return [
        { title: this.blokByNumber.tab_title },
        { title: this.blokWithoutNumber.tab_title }
      ];
    },
    years() {
      const cur = new Date().getFullYear();
      const minYear = this.maxYearCarProduction;
      const length = cur - minYear + 1;

      return Array.apply(null, Array(length)).map((i, index) => {
        const year = cur - index;

        return { title: year, value: year };
      });
    },
    locale() {
      return this.$i18n.locale;
    },
    result() {
      return this.activeTab === 0 ? this.byNumber : this.withoutNumber;
    },
    resData() {
      return this.result.data;
    },
    additionalKilometer() {
      const product = this.resData?.products?.find(i => i.additional_kilometer);

      return product?.additional_kilometer;
    },
    showAnim() {
      return !this.resData && !this.showSupport;
    },
    showSupport() {
      const codes = ['car_is_older', 'car_is_cost_less', 'car_is_cost_more'];

      return codes.includes(this.result.errorCode);
    },
    isAnyProductInactive() {
      const isInactive = this.resData?.products?.find(i => i.isInactive);

      return !!isInactive;
    },
    isProductsExist() {
      return !!this.resData?.products?.length;
    },
    starterPacks() {
      return this.resData?.products?.map(i => ({
        title: i.name,
        value: i.tariff,
      })) || [];
    },
  },
  watch: {
    heroTimespan: {
      handler(v) {
        this.byNumber.number = this.heroNumber;
        this.handleInput('byNumber', 'number');
        this.handleClickTab(0);
      }
    },
  },
  methods: {
    handleClickTab(tab) {
      if (this.pending) return;

      this.activeTab = tab;
      this.removeErrorResp();
      this.animReset();
    },
    handleInput(form, input) {
      this.$v[form][input].$reset();
      this.removeErrorResp();
    },
    async handleSubmitFirst() {
      this.$v.byNumber.$touch();
      if (this.$v.byNumber.$invalid) return;

      this.pending = true;
      this.byNumber.data = null;
      this.byNumber.errorCode = null;
      this.removeErrorResp();
      this.animStart();

      try {
        const { ref, partner } = this.$route.query;
        const number = this.byNumber.number.replace(/\s/g, '');
        const data = await this.$axios.$get(`https://core.easypeasy.com.ua/api/crm/calculation_by_car_number/${number}`, {
          headers: {
            'X-LOCALE': this.locale,
          },
          params: {
            source: ref || 'website',
            partner,
          },
        });

        this.byNumber.data = data;
        this.animStop();
        this.$fb.track('Lead');
        this.$fb.query('trackCustom', 'Calculation', { lead: 'new_lead' });
      } catch(err) {
        console.error(err);

        const { response } = err;

        this.errorResp = response?.data?.['hydra:description'];
        this.byNumber.errorCode = response?.headers['x-calculation-exception'];

        if (!this.showSupport) {
          this.animError();
        }
      } finally {
        this.pending = false;
      }
    },
    async handleSubmitSecond() {
      this.$v.withoutNumber.$touch();
      if (this.$v.withoutNumber.$invalid) return;

      this.pending = true;
      this.withoutNumber.data = null;
      this.withoutNumber.errorCode = null;
      this.removeErrorResp();
      this.animStart();

      try {
        const { ref, partner } = this.$route.query;
        const carPriceUAH = this.withoutNumber.price;
        const dateProduction = this.withoutNumber.year.value;
        const data = await this.$axios.$get('https://core.easypeasy.com.ua/api/crm/products_by_own_price', {
          headers: {
            'X-LOCALE': this.locale,
          },
          params: {
            carPriceUAH,
            dateProduction,
            source: ref || 'website',
            partner,
          },
        });

        this.withoutNumber.data = data;
        this.animStop();
        this.$fb.query('trackCustom', 'Calculation', { lead: 'new_lead' });
      } catch(err) {
        console.error(err);

        const { response } = err;

        this.errorResp = response?.data?.['hydra:description'];
        this.withoutNumber.errorCode = response?.headers['x-calculation-exception'];

        if (!this.showSupport) {
          this.animError();
        }
      } finally {
        this.pending = false;
      }
    },
    async getDefaultSettings() {
      this.initLoad = true;

      try {
        const { ref } = this.$route.query;
        const data = await this.$axios.$get('https://core.easypeasy.com.ua/api/public/default_settings', {
          headers: {
            'X-LOCALE': this.locale,
          },
          params: {
            source: ref || 'website',
          },
        });

        this.maxYearCarProduction = data.maxYearCarProduction;
        this.minPrice = data.minCostCarUAH;
        this.maxPrice = data.maxCostCarUAH;
        this.limitKilometers = data.limitKilometers;
      } catch(err) {
        console.error(err);
      } finally {
        this.initLoad = false;
      }
    },
    animInit() {
      const isMobile = window.innerWidth <= 1279 ? '-mob' : '';

      this.anim = this.$lottie.loadAnimation({
        container: this.$refs.animationElement,
        loop: true,
        autoplay: false,
        path: `/lottie/calc-${this.locale}${isMobile}.json`,
      });
    },
    animReset() {
      if (this.anim) {
        this.anim.playSegments([0, 479], true);
        this.anim.goToAndStop(0, false);
      }
    },
    animStart() {
      if (this.anim) {
        this.anim.playSegments([[23, 122], [122, 240]], true);
      }
    },
    animError() {
      if (this.anim) {
        this.anim.playSegments([[240, 348], [348, 479]], true);
      }
    },
    animStop() {
      if (this.anim) {
        this.anim.stop();
      }
    },
    aosVisibilityChanged(isVisible) {
      this.isVisibleAos = isVisible;
    },
    lottieVisibilityChanged(isVisible) {
      if (isVisible) {
        this.animInit();
      }
    },
    removeErrorResp() {
      this.errorResp = null;
    },
    filterNumber(value, space) {
      return this.$options.filters.number(value, space);
    },
    getChargeLimitText(text) {
      const str = text || '';
      const regex = /\$\{limit_km\}/gm;
      const limit_km = `${this.filterNumber(this.limitKilometers, '&nbsp;')} ${this.$t('km')}`;
      const subst = `<span class="limit-km -t5-800">${limit_km}</span>`;

      return str.replace(regex, subst);
    },
  },
  async fetch() {
    await this.getDefaultSettings();
  },
  beforeDestroy() {
    if (this.anim) this.anim.destroy();
  },
}
