//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconArrow from "~/assets/img/icons/arr-select.svg?inline";

export default {
  name: 'BaseSelect',
  components: {
    IconArrow,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
    list: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pending: {
      type: Boolean,
      default: false,
    },
    strategy: {
      type: String,
      default: 'absolute',
      validator: type => ['absolute', 'fixed'].includes(type),
    }
  },
  methods: {
    handleSelect(item, hide) {
      this.$emit('input', item);
      hide();
    },
  },
}
