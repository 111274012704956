var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-select",class:{
    'base-select--active': _vm.value,
    'base-select--error': _vm.error,
    'base-select--disabled': _vm.disabled,
    'base-select--pending': _vm.pending,
  }},[_c('VDropdown',{staticClass:"v-popper--base-select",attrs:{"distance":"0","auto-size":"","strategy":_vm.strategy,"placement":"bottom","popperClass":"popper-base-select","disabled":_vm.disabled || _vm.pending},scopedSlots:_vm._u([{key:"popper",fn:function(ref){
  var hide = ref.hide;
return [_c('div',{staticClass:"base-select__list"},[_c('div',{directives:[{name:"bar",rawName:"v-bar"}],staticClass:"base-select__list-scroll"},[_c('div',[_c('div',{staticClass:"base-select__list-wr"},_vm._l((_vm.list),function(item){return _c('div',{key:item.value,staticClass:"base-select__list-item -t1",class:{
                  'base-select__list-item--active': _vm.value && _vm.value.value === item.value,
                },on:{"click":function($event){return _vm.handleSelect(item, hide)}}},[_vm._v(_vm._s(item.title))])}),0)])])])]}}])},[_c('div',{staticClass:"base-select__wr"},[_c('div',{staticClass:"base-select__label"},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),_c('div',{staticClass:"base-select__input -g-d-f -g-ai-c -g-jc-sb"},[_c('div',{staticClass:"base-select__input-val"},[_vm._v(_vm._s(_vm.value ? _vm.value.title : ''))]),_vm._v(" "),_c('div',{staticClass:"base-select__input-arr -g-d-f -g-ai-c -g-jc-c"},[_c('IconArrow')],1)])])]),_vm._v(" "),(_vm.error)?_c('div',{staticClass:"base-select__error -t4",domProps:{"innerHTML":_vm._s(_vm.error)}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }