//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { getError } from "~/utils/vuelidate";
import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min.js';
import IconCheck from '~/assets/img/icons/check.svg?inline';

import 'vue-slider-component/dist-css/vue-slider-component.css';
import 'vue-slider-component/theme/default.css';

export default {
  name: 'UiCalculatorCalculation',
  mixins: [validationMixin],
  components: {
    VueSlider,
    IconCheck,
  },
  props: {
    title: String,
    starterPacks: {
      type: Array,
      default: () => [],
      required: true,
    },
    price: {
      type: Number,
      default: 1,
      required: true,
    },
    limitKilometers: {
      type: Number,
      default: 15000,
      required: true,
    },
    calculationId: Number,
  },
  data() {
    return {
      starterPackVal: null,
      mileageVal: 10000,
      showEmailVal: false,
      emailVal: '',
      pending: false,
      success: false,
      timeoutID: undefined,
    }
  },
  validations: {
    emailVal: {
      required,
      email,
    },
  },
  computed: {
    errors() {
      return {
        emailVal: getError(this.$v.emailVal, {
          required: this.$t('field-required'),
          email: this.$t('invalid-email'),
        }, this.$t('invalid-value')),
      };
    },
    getMileage() {
      return this.mileageVal > this.limitKilometers ? this.limitKilometers : this.mileageVal;
    },
    getReplenishment() {
      return this.getMileage * this.price;
    },
    getTotal() {
      return this.getReplenishment + this.starterPackVal.value;
    },
    showEmailForm() {
      return this.calculationId && typeof this.calculationId === 'number';
    },
  },
  watch: {
    starterPacks: {
      handler(v) {
        this.starterPackVal = v?.length ? v[0] : null;
      },
      immediate: true,
    }
  },
  methods: {
    async handleSubmit() {
      this.hideSuccess();
      this.$v.emailVal.$touch();
      if (this.$v.emailVal.$invalid) return;

      this.pending = true;

      try {
        const data = await this.$axios.$post('https://core.easypeasy.com.ua/api/crm/calculation_mail_send', {
          calculation: `api/crm/calculations/${this.calculationId}`,
          email: this.emailVal
        });
        this.showSuccess();
        this.emailVal = '';
        this.$v.emailVal.$reset();
      } catch(err) {
        console.error(err);
      } finally {
        this.pending = false;
      }
    },
    filterNumber(value, space) {
      return this.$options.filters.number(value, space);
    },
    formatTooltip(value) {
      return value > this.limitKilometers
        ? `${this.$t('more-than')} ${this.filterNumber(this.limitKilometers)} ${this.$t('km')}`
        : `${this.filterNumber(value)} ${this.$t('km')}`;
    },
    getChargeLimitText(text) {
      const str = text || '';
      const regex = /\$\{limit_km\}/gm;
      const subst = `${this.filterNumber(this.limitKilometers, '&nbsp;')}`;

      return str.replace(regex, subst);
    },
    showSuccess() {
      this.success = true;
      this.timeoutID = setTimeout(() => {
        this.success = false;
      }, 5000);
    },
    clearTimeoutSuccess() {
      if (this.timeoutID) {
        clearTimeout(this.timeoutID);
      }
    },
    hideSuccess() {
      this.clearTimeoutSuccess();
      this.success = false;
    },
    handleInputEmail() {
      this.$v.emailVal.$reset();
      if (!this.pending) {
        this.hideSuccess();
      }
    },
  },
  beforeDestroy() {
    this.clearTimeoutSuccess();
  },
}
