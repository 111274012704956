//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import QRCode from 'qrcode';
import { serialize } from '~/utils/helpers';
import { env } from '~/env';
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { getError } from "~/utils/vuelidate";
import IconCross from '~/assets/img/icons/cross.svg?inline';

export default {
  name: 'UiCalculatorProducts',
  mixins: [validationMixin],
  components: {
    IconCross,
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
    carNumber: String,
    popupAppTitle: String,
    popupAppQrDescription: String,
    popupAppFormDescription: String,
    smsText: String,
  },
  data() {
    return {
      showPopup: false,
      activeItem: null,
      shortLink: '',
      qrCodeSrc: '',
      linkPending: false,
      phoneVal: '',
      pending: false,
      success: false,
      timeoutID: undefined,
    }
  },
  validations: {
    phoneVal: {
      required,
      isValid(value) {
        return value.replace(/\D/g, "").length === 12;
      },
    },
  },
  computed: {
    errors() {
      return {
        phoneVal: getError(this.$v.phoneVal, {
          required: this.$t('field-required'),
          isValid: this.$t('invalid-value'),
        }),
      };
    },
    appLink() {
      return {
        id: '',
        url: this.shortLink,
        target: '_blank',
        linktype: 'url',
        fieldtype: 'multilink',
        cached_url: this.shortLink
      };
    },
  },
  methods: {
    async handleShowPopup(item) {
      this.activeItem = item;
      this.getShortLink(item?.id);
      this.showPopup = true;
    },
    handleClosePopup() {
      this.showPopup = false;
      this.$nextTick(() => {
        this.shortLink = '';
        this.qrCodeSrc = '';
      });
    },
    async getQrCode(value) {
      try {
        const result = await QRCode.toDataURL(value, {
          errorCorrectionLevel: 'H',
          margin: 0,
          // width: 112,
        });

        return result;
      } catch (err) {
        console.error(err);

        return '';
      }
    },
    async getShortLink(packId) {
      if (this.controller) {
        this.controller.cancel('Operation canceled due to new request.');
      }

      this.linkPending = true;
      this.controller = this.$axios.CancelToken.source();

      const carNumber = this.carNumber;
      const { ref, partner } = this.$route.query;
      const getDeepParams = serialize({ carNumber, packId, ref, partner });
      const deepQuery = getDeepParams ? `?${getDeepParams}` : '';
      const { BASE_URL, ANDROID_PACKAGE_NAME, IOS_BUNDLE_ID } = env;

      try {
        const { shortLink } = await this.$axios.$post('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBP28vR38WVabMH8DdoZcy8gN-L7Dm1GQo', {
          dynamicLinkInfo: {
            domainUriPrefix: 'https://easypeasy.page.link',
            link: `${BASE_URL}/${deepQuery}`,
            androidInfo: {
              androidPackageName: ANDROID_PACKAGE_NAME
            },
            iosInfo: {
              iosBundleId: IOS_BUNDLE_ID,
              iosAppStoreId: "1540129246",
            }
          }
        },
        { cancelToken: this.controller.token });

        this.shortLink = shortLink;
        this.qrCodeSrc = await this.getQrCode(shortLink);
      } catch (err) {
        this.shortLink = '';
        this.qrCodeSrc = '';

        if (this.$axios.isCancel(err)) {
          console.info(err);
        } else {
          console.error(err);
        }
      } finally {
        this.linkPending = false;
      }
    },
    async handleSubmit() {
      this.hideSuccess();
      this.$v.phoneVal.$touch();
      if (this.$v.phoneVal.$invalid) return;

      this.pending = true;

      try {
        const data = await this.$axios.$post('https://core.easypeasy.com.ua/api/public/client/send_sms', {
          phone: this.phoneVal.replace(/\D/g, "").slice(2),
          text: this.getSmsText()
        });
        this.showSuccess();
        this.phoneVal = '';
        this.$v.phoneVal.$reset();
      } catch(err) {
        console.error(err);
      } finally {
        this.pending = false;
      }
    },
    showSuccess() {
      this.success = true;
      this.timeoutID = setTimeout(() => {
        this.success = false;
      }, 5000);
    },
    clearTimeoutSuccess() {
      if (this.timeoutID) {
        clearTimeout(this.timeoutID);
      }
    },
    hideSuccess() {
      this.clearTimeoutSuccess();
      this.success = false;
    },
    handleInputPhone() {
      this.$v.phoneVal.$reset();
      if (!this.pending) {
        this.hideSuccess();
      }
    },
    getSmsText() {
      const str = this.smsText || '';
      const regex = /\$\{link\}/gm;

      return str.trim() ? str.replace(regex, this.shortLink) : this.shortLink;
    },
  },
  beforeDestroy() {
    this.clearTimeoutSuccess();
  },
}
