//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { TheMask } from 'vue-the-mask';
import { component as VueNumber } from '@coders-tm/vue-number-format';
import IconEmail from '~/assets/img/icons/email.svg?inline';

const defaultNumberOptions = {
  separator: ' ',
  decimal: '.',
  min: 0,
};

export default {
  name: 'BaseInput',
  components: {
    TheMask,
    VueNumber,
    IconEmail,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pending: {
      type: Boolean,
      default: false,
    },
    maskType: {
      type: String,
      default: null,
      validator: type => ['mask', 'number'].includes(type),
    },
    mask: {
      type: String,
      default: null,
    },
    numberOptions: {
      type: Object,
      default: () => defaultNumberOptions,
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
    persistentFocus: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      focused: false,
    }
  },
  computed: {
    hasValue() {
      return !!this.value?.length;
    },
    bindNumberOptions() {
      return {
        ...defaultNumberOptions,
        ...this.numberOptions,
      }
    },
  },
  methods: {
    handleChange(e) {
      const value = e.target.value;
      if (value !== this.value) {
        this.$emit('input', value);
      }
    },
    handleInput(e) {
      const value = !this.maskType ? e.target.value : e;

      this.$emit('input', value);
    },
    handleFocus(e) {
      this.focused = true;
      this.$emit('focus', e);
    },
    handleBlur(e) {
      this.focused = false;
      this.$emit('blur', e);
    },
  },
}
