//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { TheMask } from 'vue-the-mask';
import IconFlagUa from "~/assets/img/icons/ua.svg?inline";

export default {
  name: 'BaseInputPlate',
  components: {
    TheMask,
    IconFlagUa,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pending: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: 'BB #### BB',
    },
    customTokens: {
      type: Object,
      default: () => ({
        '#': { pattern: /\d/ },
        'X': { pattern: /[0-9a-zA-Z]/ },
        'S': { pattern: /[a-zA-Z]/ },
        'A': { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
        'a': { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
        '!': { escape: true },
        'B': {
          pattern: /[a-zA-Zа-щьюяґєіїА-ЩЬЮЯҐЄІЇ]/,
          transform: v => v.toLocaleUpperCase()
        },
      }),
    }
  },
  data() {
    return {
      focused: false,
    }
  },
  computed: {
    hasValue() {
      return !!this.value?.length;
    },
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e);
    },
    handleFocus(e) {
      this.focused = true;
      this.$emit('focus', e);
    },
    handleBlur(e) {
      this.focused = false;
      this.$emit('blur', e);
    },
  },
}
