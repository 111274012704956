function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
  );
};

/**
 * @typedef {string|{[key: string]: string}} ErrorDescription
 * @param {Validation} $v
 * @param {ErrorDescription|(($v: Validation) => ErrorDescription)} errorDescriber
 * @param {string} fallbackError
 * @returns {string}
 */
export function getError($v, errorDescriber, fallbackError = undefined) {
  if (!$v.$anyError) return '';
  if (isFunction(errorDescriber)) {
    errorDescriber = errorDescriber($v);
  }
  if (typeof errorDescriber === 'string') return errorDescriber;
  var keys = Object.keys(errorDescriber);
  for (const key of keys) {
    if (!$v[key]) return errorDescriber[key] || '';
  }
  return fallbackError || 'UNKNOWN ERROR';
};
