//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconCheck from '~/assets/img/icons/checkbox.svg?inline'

export default {
  name: 'BaseCheckbox',
  components: {
    IconCheck,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      required: true,
    },
    error: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        if (this.disabled) return
        this.$emit('input', value)
      },
    },
  },
}
